<template>
	<div class='pt30 pb40'>
		<!-- 董事长 -->
		<div>
			<img src='@/assets/images/aboutUs/chairmanImg.png' class="w275 h414" style="margin: 0 auto;">
			<div class="mt20 mb13 fs16 fw-600 text-center">王涛 董事长</div>
			<div class="fs11 color9D ml16 mr16" style="text-indent: 2em;">

			</div>
		</div>
		<!-- //董事长 -->

		<div class="mt30" v-for="(item,i) in teamList" :key="i">
			<img :src="item.wapPic" class="w275 h309" style="margin: 0 auto;">
			<div class="mt20 mb4 fs16 fw-600 text-center">{{item.memberName}}</div>
			<div class="fs11 color9D text-center">{{item.memberPosition}}</div>
		</div>
	</div>
</template>

<script>
	import { getTeam } from '@/api'
	export default {
		data() {
			return {
				teamList: [],

			};
		},

		components: {},

		created() {
			// console.log(111,this.$route.path);
			this.$store.commit('editNavUrl', this.$route.path)
			this.getTeam()
		},

		mounted() {},

		methods: {
			async getTeam() {
				const res = await getTeam()
				this.teamList = res.data
			}
		}
	}
</script>
<style lang='scss' scoped>
	img {
		display: block;
	}
</style>
